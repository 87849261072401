<template>
	<b-modal
		centered
		no-close-on-backdrop
		hide-footer
		size="sm"
		:title="title"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<template v-if="!info._readonly">
				<div class="d-flex justify-content-start align-items-center gap-x-1">
					<span class="cursor-pointer">
						1. Agregar diagnostico(s) al {{ isChick ? "pollo" : "ejemplar" }}
					</span>
				</div>
				<div class="d-flex justify-content-end">
					<b-button
						class="btn-icon"
						variant="outline-success"
						size="sm"
						@click="createDiagnostic.show = !createDiagnostic.show"
					>
						<feather-icon icon="PlusIcon" />
					</b-button>
				</div>
				<v-select
					label="text"
					class="my-1"
					:options="diagnosticOpts"
					:reduce="(opt) => opt.value"
					v-model="selected"
					multiple
					:selectable="(opt) => !opt.exist"
					placeholder="Seleccionar diagnósticos"
				/>
				<div class="d-flex justify-content-end">
					<b-button variant="success" :disabled="selected.length == 0" @click="insert">Guardar</b-button>
				</div>
				<hr />
			</template>
			<span class="d-block mb-1" v-if="!info._readonly">
				2. Diagnostico(s) del {{ isChick ? "pollo" : "ejemplar" }}
			</span>
			<b-table-simple small sticky-header="40vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Diagnostico</b-th>
						<b-th v-if="!info._readonly">Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>{{ row.name }}</b-td>
						<b-td v-if="!info._readonly">
							<b-button class="btn-icon" variant="flat-danger" @click="remove(row)">
								<feather-icon icon="TrashIcon" />
							</b-button>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="2" class="text-center">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<CreateDiagnosticModal
			:show="createDiagnostic.show"
			:info="createDiagnostic.info"
			@closing="createDiagnostic.show = false"
			@refresh="getOpts"
		/>
	</b-modal>
</template>

<script>
import nurseryService from "@/services/nursery/default.service"
import nurseryBrainService from "@/services/nursery/settings.service"

import CreateDiagnosticModal from "@/views/brain/nursery/components/CreateDiagnosticModal.vue"

export default {
	components: { CreateDiagnosticModal },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: "",
		rows: [],
		diagnosticOpts: [],
		selected: [],
		showSelect: false,
		createDiagnostic: { show: false, info: {} },
	}),
	computed: {
		isChick() {
			return !!this.info.chick
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.rows = []
			this.selected = []
			this.showSelect = false
			this.$emit("closing")
		},
		async getOpts() {
			this.isLoading = true
			const { data } = await nurseryBrainService.getDiagnostic({ status_id: 1 })
			const existingDiagnoses = this.rows.map((d) => d.diagnostic_id)
			this.diagnosticOpts = data.map((opt) => ({
				value: opt.id,
				text: opt.name,
				exist: existingDiagnoses.includes(opt.id) ? true : false,
			}))
			this.isLoading = false
		},
		async getRows() {
			this.isLoading = true
			const { data } = await nurseryService.getDiagnosticList({ id: this.info.id })
			this.rows = data
			await this.getOpts()
			this.isLoading = false
		},
		async insert() {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se agregara ${this.selected.length} diagnostico(s) al ${this.isChick ? "pollo" : "ejemplar"} ${
					this.isChick ? this.info.chick.correlative : this.info.specimen.alias || this.info.specimen.plate
				}`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			const existingDiagnoses = this.rows.map((d) => d.diagnostic_id)
			await nurseryService.insertDiagnostics({
				diagnoses: [...existingDiagnoses, ...this.selected],
				nursing_id: this.info.id,
			})
			this.selected = []
			this.isLoading = false
			this.getRows()
			this.$emit("refresh")
		},
		async remove(row) {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se eliminara el diagnostico ${row.name}`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			const newDiagnoses = this.rows
				.filter((d) => d.diagnostic_id != row.diagnostic_id)
				.map((d) => d.diagnostic_id)
			await nurseryService.insertDiagnostics({
				diagnoses: newDiagnoses,
				nursing_id: this.info.id,
			})
			this.isLoading = false
			this.getRows()
			this.$emit("refresh")
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getRows()
				this.title = `${this.info._readonly ? "Ver diagnosticos" : "Diagnosticos"} / ${
					this.isChick ? this.info.chick.correlative : this.info.specimen.alias || this.info.specimen.plate
				}`
			}
		},
	},
}
</script>
